import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { SettingsSecurityPassword } from 'App/components'
import { State } from 'redux/rootReducer'
import { showModalAction, getWhoAmiRequest } from 'store'

export type TSettingsSecurityPasswordContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  isPasswordExist: state.global.accountData?.isPasswordExist
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction,
      getWhoAmi: getWhoAmiRequest
    },
    dispatch
  )

export const SettingsSecurityPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSecurityPassword)
