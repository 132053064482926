import { Redirect, RouteProps } from 'react-router-dom'
import {
  AccountTypeNames,
  ContactStatusEnum,
  BusinessAccountRoleNameEnum,
  ProducedNotificationsEnum
} from '@medentee/enums'

import {
  CHAT_PATH,
  CREATE_GROUP_CHAT_PATH,
  EDIT_GROUP_CHAT_PATH,
  EInvitationType
} from 'globalConstants'
import {
  Contacts,
  CaseDetails,
  CaseHistory,
  Cases,
  Chat,
  CreateCommunity,
  FilePage,
  Invitations,
  Notifications,
  Organization,
  Organizations,
  SandboxScreen,
  Settings,
  SubscriptionAndPayments,
  SubscriptionUpgrade,
  Community,
  Communities,
  Events
} from 'App/screens'
import {
  AccountAndNotificationsContainer,
  ActiveSubscriptionsListContainer,
  ContactsList,
  CaseList,
  FileListWrapperContainer,
  FileSharedListWrapperContainer,
  InvitationCasesPendingContainer,
  InvitationsContactsContainer,
  InvitationsSentNewUsersContainer,
  NotificationsP2PContainer,
  OrganizationInvitationsAdministration,
  OrganizationInvitationsNewUsers,
  OrganizationInvitationsRegistered,
  OrganizationStaffRequests,
  PaymentsContainer,
  SettingsSecurityAndLoginContainer,
  TransferMDT,
  TrashBin,
  TRoleRouteContainerOwnProps,
  UserProfileBasicInformationContainer,
  UserProfileContainer,
  UserProfilePreviewWrapperContainer,
  UserProfileProfessionsContainer,
  UserProfileWrapperContainer
} from 'App/containers'
import {
  EducationList,
  LicensesList,
  PublicationsList,
  SettingsBusinessInfo,
  SettingsPersonalInfo,
  Support,
  UserProfileOnTheWeb,
  WorkExperienceList
} from 'App/components'
import { EBusinessAdminConfirmFrom } from 'enums'

import {
  OrganizationsInvitationsReceived,
  OrganizationsInvitationsSent
} from './containers/Organization/OrganizationsInvitations'
import {
  JoinViaLinkToCommunityChannel,
  JoinViaLinkToCommunityBroadcast
} from './screens/CommunitiesJoinViaLink'
import { JoinViaLinkOrganizationChannel } from './screens/OrganizationsJoinViaLink'
import { CreateEvent } from './screens/Events/CreateEvent'
import { EventSettings } from './screens/Events/EventSettings'
import { EventBasicInfo } from './screens/Events/EventBasicInfo'
import { AttendeesInfo } from './screens/Events/AttendeesInfo'
import { OrganizerSettings } from './screens/Events/OrganizerSettings'
import { EventBranding } from './screens/Events/EventBranding'
import { EventLinks } from './screens/Events/EventLinks'
import { CommunitySettings } from './screens/Community/CommunitySettings'
import { EventNetworking } from './components/Events/EventNetworking'
import { EventNetworkingList } from './components/Events/EventNetworking/EventNetworkingList'
import { JoinViaLinkToEventBroadcast, JoinViaLinkToEventChannel } from './screens/EventsJoinViaLink'
import { CommunityBasicInfo, CommunityOrganizerSettings } from './components/Communities'
import { CommunityBranding } from './screens/Community/CommunityBranding'
import { PublicEventInfo } from './screens/Events/PublicEventInfo'
import { PublicCommunityInfo } from './screens/Community/PublicCommunityInfo'

export const getKeyForRoute = (path?: RouteProps['path'], index: number = Math.random()) => {
  if (!path) {
    return index
  }

  if (typeof path === 'string') {
    return `${path}_${index}`
  }

  const key = path.reduce<string>((init, item) => {
    init += item

    return init
  }, '')

  return `${key}_${index}`
}

export const routes: TRoleRouteContainerOwnProps[] = [
  {
    path: '/contacts',
    component: () => <Contacts />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL]
  },
  {
    exact: true,
    path: [CHAT_PATH, CREATE_GROUP_CHAT_PATH, EDIT_GROUP_CHAT_PATH],
    component: Chat
  },
  {
    exact: true,
    path: '/sandbox/:id?',
    component: SandboxScreen
  },
  {
    path: '/files',
    component: () => <FilePage />
  },
  {
    exact: true,
    path: ['/cases', '/cases/invitations'],
    component: () => <Cases />
  },
  {
    exact: true,
    path: '/cases/:id/:who?',
    component: CaseDetails
  },
  {
    exact: true,
    path: '/cases/:id/:who/history',
    component: CaseHistory
  },
  {
    exact: true,
    path: ['/profile/edit'],
    component: () => <Redirect to="/profile/edit/basic" />
  },
  {
    strict: true,
    path: [
      '/profile/edit/basic',
      '/profile/edit/professions',
      '/profile/edit/web',
      '/profile/edit/general',
      '/profile/edit/work-experience',
      '/profile/edit/education',
      '/profile/edit/licenses',
      '/profile/edit/publications'
    ],
    component: () => <UserProfileContainer />
  },
  {
    exact: true,
    path: '/profile/preview',
    component: () => <UserProfilePreviewWrapperContainer />
  },
  {
    exact: true,
    path: ['/profile/:id', '/profile/t/:token'],
    component: () => <UserProfileWrapperContainer />
  },
  {
    path: '/notifications',
    component: () => <Notifications />
  },
  {
    path: '/settings',
    component: () => <Settings />
  },
  {
    exact: true,
    path: '/subscription-upgrade/:accountTypeId/:accountId',
    component: () => <SubscriptionUpgrade />
  },
  {
    path: '/subscription-and-payments',
    component: () => <SubscriptionAndPayments />,
    allowedAccountTypes: [AccountTypeNames.PROFESSIONAL, AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
  },
  // Commented out due to the need to disable functionality for the task MED-7867
  // {
  //   path: '/adverts',
  //   component: Adverts,
  //   allowedAccountTypes: [AccountTypeNames.PROFESSIONAL, AccountTypeNames.BUSINESS]
  // },
  {
    path: '/organization',
    component: Organization,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  },
  {
    path: '/organizations',
    component: Organizations,
    allowedAccountTypes: [AccountTypeNames.PROFESSIONAL]
  },
  {
    exact: true,
    path: '/join/organizations/:id/chat/t/:token',
    component: () => <JoinViaLinkOrganizationChannel variant="chat" />
  },

  // Commented out due to the need to disable functionality for the task MED-7867

  // {
  //   exact: true,
  //   path: '/showcases/p/:id',
  //   component: Showcase,
  //   allowedAccountTypes: [AccountTypeNames.PROFESSIONAL]
  // },
  // {
  //   path: '/showcases',
  //   component: Showcases,
  //   allowedAccountTypes: [AccountTypeNames.PROFESSIONAL]
  // },
  {
    exact: true,
    path: '/community/create',
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ],
    component: CreateCommunity
  },
  {
    exact: true,
    path: ['/communities/t/info/:token', '/communities/:id/p/info'],
    component: PublicCommunityInfo
  },
  {
    exact: true,
    path: '/join/communities/:id/chat/t/:token',
    component: () => <JoinViaLinkToCommunityChannel variant="chat" />
  },
  {
    exact: true,
    path: '/join/communities/:id/news/t/:token',
    component: () => <JoinViaLinkToCommunityChannel variant="news" />
  },
  {
    exact: true,
    path: '/join/communities/:id/broadcast/t/:token',
    component: JoinViaLinkToCommunityBroadcast
  },
  {
    path: '/community/:id/edit',
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ],
    component: CommunitySettings
  },
  {
    path: '/community',
    component: Community,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  },
  {
    path: '/communities',
    component: Communities,
    allowedAccountTypes: [AccountTypeNames.PROFESSIONAL],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  },
  {
    path: '/events/:id/highlights/networking',
    allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ],
    component: EventNetworking
  },
  {
    exact: true,
    path: '/events/create',
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ],
    component: CreateEvent
  },
  {
    exact: true,
    path: ['/events/t/info/:token', '/events/:id/p/info'],
    component: PublicEventInfo
  },
  {
    exact: true,
    path: '/join/events/:id/chat/t/:token',
    component: () => <JoinViaLinkToEventChannel variant="chat" />
  },
  {
    exact: true,
    path: '/join/events/:id/news/t/:token',
    component: () => <JoinViaLinkToEventChannel variant="news" />
  },
  {
    exact: true,
    path: '/join/events/:id/broadcast/t/:token',
    component: JoinViaLinkToEventBroadcast
  },
  {
    path: '/events/:id/edit',
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ],
    component: EventSettings
  },
  {
    path: '/events',
    component: Events,
    allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  }
]

/** Nested routes for /files */
export const fileNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/files',
    component: () => <FileListWrapperContainer />
  },
  {
    path: '/files/shared',
    component: () => <FileSharedListWrapperContainer />
  },
  {
    path: '/files/trash-bin',
    component: () => <TrashBin />
  }
]
/** Nested routes for /profile */
export const profileNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/profile/edit/basic',
    component: () => <UserProfileBasicInformationContainer />
  },
  {
    exact: true,
    path: '/profile/edit/professions',
    component: () => <UserProfileProfessionsContainer />
  },
  {
    exact: true,
    path: '/profile/edit/web',
    component: () => <UserProfileOnTheWeb />
  },
  {
    exact: true,
    path: '/profile/edit/work-experience',
    component: () => <WorkExperienceList />
  },
  {
    exact: true,
    path: '/profile/edit/education',
    component: () => <EducationList />
  },
  {
    exact: true,
    path: '/profile/edit/licenses',
    component: () => <LicensesList />
  },
  {
    exact: true,
    path: '/profile/edit/publications',
    component: () => <PublicationsList />
  }
]

export const settingsNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/settings/info',
    component: () => <SettingsPersonalInfo />,
    allowedAccountTypes: [AccountTypeNames.PROFESSIONAL]
  },
  {
    exact: true,
    path: '/settings/company-info',
    component: () => <SettingsBusinessInfo />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
  },
  {
    exact: true,
    path: '/settings/account-notifications',
    component: () => <AccountAndNotificationsContainer />
  },
  {
    exact: true,
    path: '/settings/security',
    component: () => <SettingsSecurityAndLoginContainer />,
    allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
  },
  {
    exact: true,
    path: '/settings/support',
    component: () => <Support />,
    allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
  }
]

export const subscriptionAndPaymentsNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/subscription-and-payments/subscription',
    component: () => <ActiveSubscriptionsListContainer />,
    allowedAccountTypes: [AccountTypeNames.PROFESSIONAL, AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
  },
  {
    exact: true,
    path: '/subscription-and-payments/balance',
    component: () => <PaymentsContainer />,
    allowedAccountTypes: [AccountTypeNames.PROFESSIONAL, AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
  },
  {
    exact: true,
    path: '/subscription-and-payments/transfer',
    component: () => <TransferMDT />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
  }
]

export const advertsNestedRoutes: TRoleRouteContainerOwnProps[] = []
// Commented out due to the need to disable functionality for the task MED-7867

// export const advertsNestedRoutes: TRoleRouteContainerOwnProps[] = [
//   {
//     exact: true,
//     path: '/adverts/connector',
//     component: () => <ConnectorList />,
//     allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
//     allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
//   },
//   {
//     exact: true,
//     path: '/adverts/my-adverts',
//     component: () => <MyAdvertsList />,
//     allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
//     allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
//   },
//   {
//     exact: true,
//     path: '/adverts/my-requests',
//     component: () => <MyRequestsList />,
//     allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
//     allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
//   }
// ]

export const contactsNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/contacts',
    component: () => <ContactsList />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL]
  },
  {
    path: '/contacts/invitations',
    component: () => <Invitations />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL]
  }
]

export const casesNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/cases',
    component: () => <CaseList />,
    trackNotifications: new Set([
      ProducedNotificationsEnum.CASE_DESCRIPTION_UPDATED,
      ProducedNotificationsEnum.ACCEPTED_CASE_INVITE,
      ProducedNotificationsEnum.CASE_OPINION_CREATED,
      ProducedNotificationsEnum.CASE_DESCRIPTION_UPDATED,
      ProducedNotificationsEnum.CASE_STATUS_CHANGED,
      ProducedNotificationsEnum.AUTO_APPROVED_CASE_INVITE,
      ProducedNotificationsEnum.CASE_DELETED
    ])
  },
  {
    path: '/cases/invitations',
    trackNotifications: new Set([ProducedNotificationsEnum.RECEIVED_NEW_CASE_INVITE]),
    component: () => <InvitationCasesPendingContainer />
  }
]

/** Invitation routes for /contacts/invitations */
export const contactsInvitationNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/contacts/invitations/received',
    component: () => (
      <InvitationsContactsContainer
        type={EInvitationType.RECEIVED}
        status={ContactStatusEnum.PENDING}
      />
    ),
    trackNotifications: new Set([ProducedNotificationsEnum.RECEIVED_NEW_CONTACT_INVITE]),
    allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL]
  },

  {
    exact: true,
    path: '/contacts/invitations/sent',
    trackNotifications: new Set([ProducedNotificationsEnum.ACCEPTED_CONTACT_INVITE]),
    component: () => <InvitationsContactsContainer type={EInvitationType.SENT} />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL]
  },

  {
    exact: true,
    path: '/contacts/invitations/sent/new-users',
    component: () => <InvitationsSentNewUsersContainer />,
    allowedAccountTypes: [AccountTypeNames.PROFESSIONAL]
  }
]

/** Invitation routes for /organization/invitations */
export const organizationInvitationNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/organization/invitations/received',
    component: () => <OrganizationStaffRequests />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS]
  },
  {
    exact: true,
    path: '/organization/invitations/registered',
    component: () => <OrganizationInvitationsRegistered />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS]
  },
  {
    exact: true,
    path: '/organization/invitations/new-users',
    component: () => <OrganizationInvitationsNewUsers />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS]
  },
  {
    exact: true,
    path: '/organization/invitations/administration',
    component: () => <OrganizationInvitationsAdministration />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
  }
]

/** Invitation routes for /organizations/invitations */
export const organizationsInvitationsNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/organizations/invitations/received',
    component: () => (
      <OrganizationsInvitationsReceived from={EBusinessAdminConfirmFrom.STAFF_RECEIVED} />
    ),
    allowedAccountTypes: [AccountTypeNames.PROFESSIONAL]
  },
  {
    exact: true,
    path: '/organizations/invitations/sent',
    component: () => <OrganizationsInvitationsSent />,
    allowedAccountTypes: [AccountTypeNames.PROFESSIONAL]
  }
]

// Commented out due to the need to disable functionality for the task MED-7867
export const showcaseNestedRoutes: TRoleRouteContainerOwnProps[] = []
// export const showcaseNestedRoutes: TRoleRouteContainerOwnProps[] = [
//   {
//     exact: true,
//     path: '/showcases/feed',
//     component: () => <ShowcaseFeed />,
//     allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
//     allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
//   },
//   {
//     exact: true,
//     path: '/showcases/my',
//     component: () => <MyShowcases />,
//     allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
//     allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
//   },
//   {
//     exact: true,
//     path: '/showcases/saved',
//     component: () => <SavedShowcases />,
//     allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
//     allowedBusinessRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
//   }
// ]

export const notificationsNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/notifications',
    component: () => <NotificationsP2PContainer />
  }
  // Commented out due to the need to disable functionality for the task MED-7867

  // {
  //   exact: true,
  //   path: '/notifications/showcases',
  //   component: () => <NotificationsShowcasesList />,
  //   allowedAccountTypes: [AccountTypeNames.PROFESSIONAL]
  // }
]

export const communitySettingsNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/community/:id/edit/basic',
    component: () => <CommunityBasicInfo />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  },
  {
    path: '/community/:id/edit/branding',
    component: () => <CommunityBranding />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  },
  {
    path: '/community/:id/edit/organizer-settings',
    component: () => <CommunityOrganizerSettings />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  }
]

export const eventsSettingsNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/events/:id/edit/basic',
    component: () => <EventBasicInfo />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  },
  {
    path: '/events/:id/edit/attendees',
    component: () => <AttendeesInfo />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  },
  {
    path: '/events/:id/edit/organizer-settings',
    component: () => <OrganizerSettings />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  },
  {
    path: '/events/:id/edit/branding',
    component: () => <EventBranding />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  },
  {
    path: '/events/:id/edit/links',
    component: () => <EventLinks />,
    allowedAccountTypes: [AccountTypeNames.BUSINESS],
    allowedBusinessRoles: [
      BusinessAccountRoleNameEnum.ADMIN,
      BusinessAccountRoleNameEnum.SUPERADMIN
    ]
  }
]

export const eventNetworkingNestedRoutes: TRoleRouteContainerOwnProps[] = [
  {
    exact: true,
    path: '/events/:id/highlights/networking',
    component: () => <EventNetworkingList />
  },
  {
    exact: true,
    path: '/events/:id/highlights/networking/favorites',
    component: () => <EventNetworkingList isFavorite={true} />
  }
]
