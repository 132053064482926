import { useCallback, useEffect, useState } from 'react'
import cls from 'classnames'
import { ErrorCodesEnum } from '@medentee/enums'

import { getMapComponent, isMatchErrorCode, setRedirectToastInfo } from 'utils'
import { ErrorModal, TwoFactorChecker, Steps, SettingsSecurityChangePassword } from 'App/components'
import { TSettingsSecurityChangePasswordConfirmContainerProps } from 'App/containers'
import { useSteps } from 'App/hooks'

import styles from './SettingsSecurityChangePasswordConfirm.module.scss'

export type TSettingsSecurityChangePasswordConfirmProps = Pick<
  TSettingsSecurityChangePasswordConfirmContainerProps,
  | 'error'
  | 'changePassword'
  | 'expiredTokenDate'
  | 'processing'
  | 'clearError'
  | 'clearChangePassword'
  | 'isPasswordExist'
>

type TStepContentProps = Pick<
  TSettingsSecurityChangePasswordConfirmProps,
  'expiredTokenDate' | 'processing' | 'isPasswordExist'
> & {
  limitExpired: boolean
  codeInvalid: boolean
  codeExhausted: boolean
  onConfirm: (code: string) => void
  onChangePassword: (newPassword: string) => void
  onResendCode: () => void
  onClearInvalid: () => void
}

enum EStep {
  SET_PASSWORD = 0,
  TWO_FA = 1
}

const STEP_CONTENT = new Map<EStep, (props: TStepContentProps) => JSX.Element>()
  .set(EStep.SET_PASSWORD, ({ onChangePassword, processing, isPasswordExist }) => (
    <>
      <SettingsSecurityChangePassword
        onChangePassword={onChangePassword}
        processing={processing}
        isPasswordExist={isPasswordExist}
      />
    </>
  ))
  .set(
    EStep.TWO_FA,
    ({
      expiredTokenDate,
      processing,
      onClearInvalid,
      codeInvalid,
      onConfirm,
      onResendCode,
      codeExhausted,
      limitExpired
    }) => (
      <TwoFactorChecker
        processing={processing}
        onConfirm={onConfirm}
        onResendCode={onResendCode}
        expiredTokenDate={expiredTokenDate}
        invalid={codeInvalid}
        codeExhausted={codeExhausted}
        onClearInvalid={onClearInvalid}
        limitExpired={limitExpired}
      />
    )
  )

export const SettingsSecurityChangePasswordConfirm = ({
  isPasswordExist,
  error,
  expiredTokenDate,
  processing,
  changePassword,
  clearError,
  clearChangePassword
}: TSettingsSecurityChangePasswordConfirmProps) => {
  const codeInvalid = isMatchErrorCode(error, ErrorCodesEnum.TOKEN_INVALID)
  const codeExhausted = isMatchErrorCode(error, ErrorCodesEnum.TOKEN_EXHAUSTED)
  const limitExpired = isMatchErrorCode(error, ErrorCodesEnum.EXCEEDING_TOKENS_COUNT_LIMIT)
  const { step, setNextStep } = useSteps(EStep.SET_PASSWORD)
  const [password, setPassword] = useState<string>('')

  const handleClearError = useCallback(() => {
    clearError()
  }, [clearError])

  const handleConfirm = useCallback(
    (code: string) => {
      changePassword({
        token: code,

        newPassword: password
      })
    },
    [changePassword, password]
  )

  const handleResendCode = useCallback(() => {
    changePassword({
      isResend: true,
      newPassword: password
    })
  }, [changePassword, password])

  const handleChangePassword = useCallback(
    (newPassword) => {
      changePassword({
        newPassword
      })
      setPassword(newPassword)
      clearError()
    },
    [changePassword, clearError]
  )

  useEffect(
    () => () => {
      clearChangePassword()
      setRedirectToastInfo(null)
    },
    [clearChangePassword]
  )

  useEffect(() => {
    if (step === EStep.SET_PASSWORD && expiredTokenDate) {
      setNextStep()
    }
  }, [step, expiredTokenDate, setNextStep])

  return (
    <div className={styles.root}>
      <Steps
        className={cls(styles.steps, step === EStep.TWO_FA && styles.second)}
        current={step}
        stepsArray={[{}, {}]}
      />

      {getMapComponent(STEP_CONTENT, step, {
        codeInvalid,
        codeExhausted,
        limitExpired,
        expiredTokenDate,
        processing,
        isPasswordExist,
        onClearInvalid: handleClearError,
        onResendCode: handleResendCode,
        onConfirm: handleConfirm,
        onChangePassword: handleChangePassword
      })}

      {!codeInvalid && !codeExhausted && <ErrorModal error={error} />}
    </div>
  )
}
