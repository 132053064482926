import { useCallback } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils'
import { Button, ETextFieldSize, PasswordField, PasswordStrengthChecker } from 'App/components'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'utils/validation/constants'

import styles from './SettingsSecurityChangePassword.module.scss'

export type TSettingsSecurityChangePasswordProps = {
  isPasswordExist?: boolean
  onChangePassword: (newPassword: string) => void
  processing: boolean
}

type TFormValues = {
  password?: string
  repeatPassword?: string
}

export const SettingsSecurityChangePassword = ({
  isPasswordExist,
  onChangePassword,
  processing
}: TSettingsSecurityChangePasswordProps) => {
  const { t } = useTranslation()

  const handleChangePassword = useCallback(
    (values: TFormValues) => {
      const { password, repeatPassword } = values

      if (repeatPassword && password) {
        onChangePassword(password)
      }
    },
    [onChangePassword]
  )
  const passwordValidation = validation.composeValidators(
    validation.required(),
    validation.minLength(MIN_PASSWORD_LENGTH),
    validation.maxLength(MAX_PASSWORD_LENGTH),
    validation.isPassword(t('validationErrors.incorrectEntry', { ns: 'errors' }))
  )
  return (
    <Form<TFormValues> onSubmit={handleChangePassword}>
      {({ form, handleSubmit, invalid }) => (
        <form className={styles.root} onSubmit={handleSubmit}>
          <Field name="password" validate={passwordValidation}>
            {({ input }) => (
              <PasswordField
                {...input}
                classes={{ root: styles.fieldItem }}
                topLabel={t('modal.changePassword.newPasswordField')}
              />
            )}
          </Field>

          <Field
            name="repeatPassword"
            validate={validation.composeValidators(
              validation.required(),
              validation.isPasswordConfirm()
            )}
          >
            {({ input }) => (
              <PasswordField
                {...input}
                size={ETextFieldSize.SM}
                classes={{ root: styles.fieldItem }}
                topLabel={t('modal.changePassword.repeatPasswordField')}
              />
            )}
          </Field>

          <PasswordStrengthChecker
            password={form.getFieldState('password')?.value ?? ''}
            repeatPassword={form.getFieldState('repeatPassword')?.value ?? ''}
          />

          <Button className={styles.button} type="submit" disabled={invalid} loading={processing}>
            {isPasswordExist
              ? t('modal.changePassword.change_submitButton')
              : t('modal.changePassword.create_submitButton')}
          </Button>
        </form>
      )}
    </Form>
  )
}
