import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, SettingsItemDescription, SettingsItemTitle } from 'App/components'
import { EModalComponents, TSettingsSecurityPasswordContainerProps } from 'App/containers'

import styles from './SettingsSecurityPassword.module.scss'

export type TSettingsSecurityPasswordProps = TSettingsSecurityPasswordContainerProps

export const SettingsSecurityPassword = ({
  showModal,
  getWhoAmi,
  isPasswordExist
}: TSettingsSecurityPasswordProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    getWhoAmi()
  }, [getWhoAmi])

  const handleChange = useCallback(() => {
    showModal({
      modalTitle: isPasswordExist
        ? t('modal.changePassword.change_title')
        : t('modal.changePassword.create_title'),
      modalType: EModalComponents.CHANGE_PASSWORD
    })
  }, [showModal, t, isPasswordExist])

  return (
    <>
      <SettingsItemTitle className={styles.title}>
        {t('settings.security.password.title')}
      </SettingsItemTitle>
      <SettingsItemDescription>
        {isPasswordExist
          ? t('settings.security.password.description_change')
          : t('settings.security.password.description_create')}
      </SettingsItemDescription>

      <Button variant="outlined" size="sm" onClick={handleChange} className={styles.button}>
        {isPasswordExist
          ? t('settings.security.password.changeButton')
          : t('settings.security.password.createButton')}
      </Button>
    </>
  )
}
