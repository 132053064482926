import { useMemo } from 'react'

import { useAppSelector } from 'store'
import { UnityHubNav, TUnityHubNavProps, TUnityHubNavItem } from 'App/components/UnityHub'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

type TCommunitiesNavProps = Pick<TUnityHubNavProps, 'loading'>

export const CommunitiesNav = ({ loading }: TCommunitiesNavProps) => {
  const { list, ids } = useAppSelector((state) => state.communities.data)
  const notifications = useAppSelector((state) => state.notifications.general.communities.list)
  const user = useAppSelector((state) => state.global.accountData)

  const items = useMemo<TUnityHubNavItem[]>(
    () =>
      ids.map((id) => ({
        id,
        to: `/communities/${id}`,
        name: list[id]?.title,
        live: list[id]?.isLiveBroadcast,
        count: notifications?.[id]?.totalUnreadCount
      })),
    [ids, list, notifications]
  )

  const canCreate = useMemo(() => {
    const maxCommunities = user?.maxCreatedCommunities || 1
    return ids.length < maxCommunities
  }, [ids.length, user?.maxCreatedCommunities])

  const controls = useMemo<TUnityHubNavProps['controls']>(() => {
    if (!canCreate) {
      return
    }

    return {
      items: [
        {
          icon: <PlusIcon />,
          to: '/community/create',
          id: 'create-community'
        }
      ],
      options: { bottom: false }
    }
  }, [canCreate])

  if (!loading && !ids.length) {
    return null
  }

  return <UnityHubNav type="community" items={items} controls={controls} loading={loading} />
}
