import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import {
  createLoadingSelector,
  getAccountSettingsRequest,
  GET_ACCOUNT_SETTINGS,
  getRelatedAccountsRequest,
  getWhoAmiRequest
} from 'store'
import { SettingsSecurityAndLogin } from 'App/components'

export type TSettingsSecurityAndLoginContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_ACCOUNT_SETTINGS])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  accountType: state.global.accountData?.type.name,
  hasConnectedAccount: state.global.accountData?.hasConnectedAccount,
  isPasswordExist: state.global.accountData?.isPasswordExist
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAccountSettings: getAccountSettingsRequest,
      getRelatedAccounts: getRelatedAccountsRequest,
      getWhoAmi: getWhoAmiRequest
    },
    dispatch
  )

export const SettingsSecurityAndLoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSecurityAndLogin)
