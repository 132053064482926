import { useEffect } from 'react'
import { Switch } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  GET_COMMUNITIES,
  getCommunitiesRequest,
  LOST_ACCESS_TO_COMMUNITY,
  resetCommunities,
  useAppDispatch,
  useAppSelector
} from 'store'
import { shouldSkipAction } from 'services/skipWSActions'
import { ESocketEmitEventNamesCommunities } from 'services/webSocket'
import { toastDefaultOptions } from 'globalConstants'
import { UnityHubLayout } from 'App/components/UnityHub'
import { CommunityView } from 'App/components/Communities'
import { RoleRouteContainer } from 'App/containers'

import { toast } from '../../components/ToastContainer'
import { useNotificationTracker } from '../../hooks'

import { CommunitiesNav } from './../Communities/CommunitiesNav'
import { CommunityEmpyState } from './CommunityEmptyState'

const loadingSelector = createLoadingSelector([GET_COMMUNITIES])

const NOTIFICATIONS = new Set([ESocketEmitEventNamesCommunities.LOST_ACCESS_TO_COMMUNITY])

export const Community = () => {
  const { replace } = useHistory()

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  useNotificationTracker({
    channel: 'community',
    notifications: NOTIFICATIONS,
    onOccurrence: (payload) => {
      dispatch(getCommunitiesRequest())

      if (shouldSkipAction({ type: LOST_ACCESS_TO_COMMUNITY, payload })) {
        replace('/community')
        toast.success(t('common.toast.communityDeleted'), toastDefaultOptions)
      } else {
        replace('/communities')
        toast.error(
          t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
          toastDefaultOptions
        )
      }
    }
  })

  const loading = useAppSelector(loadingSelector)
  const community = useAppSelector((state) => {
    const firstCommunity = state.communities.data.ids[0]
    return state.communities.data.list[firstCommunity]
  })

  const shouldShowEmpty = !community && !loading

  useEffect(() => {
    dispatch(getCommunitiesRequest())

    return () => {
      dispatch(resetCommunities())
    }
  }, [dispatch])

  if (shouldShowEmpty) {
    return <CommunityEmpyState />
  }

  if (loading || !community) {
    return null
  }

  return (
    <UnityHubLayout>
      <CommunitiesNav loading={loading} />
      <Switch>
        <RoleRouteContainer>
          <CommunityView community={community} loading={loading} />
        </RoleRouteContainer>
      </Switch>
    </UnityHubLayout>
  )
}
